import { Component, OnInit } from '@angular/core';
import { HostListener } from '@angular/core/src/metadata/directives';

@Component({
  selector: 'app-preview-page',
  templateUrl: './preview-page.component.html',
  styleUrls: ['./preview-page.component.scss']
})
export class PreviewPageComponent implements OnInit {

  ratio = (window.outerWidth / window.outerHeight) / (16 / 9);
  multiply = window.outerHeight * this.ratio / 360;

  constructor() { }

  ngOnInit() {
    setTimeout(() => alert('Натисніть F11, а потім PrintScreen, щоб скопіювати зображення у буфер обміну.'),
      500);
  }
}
