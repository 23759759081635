import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { PhotoPageComponent } from './components/photo-page/photo-page.component';
import { PreviewPageComponent } from './components/preview-page/preview-page.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent
  },
  {
    path: 'photo',
    component: PhotoPageComponent
  },
  {
    path: 'preview',
    component: PreviewPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
