import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { PhotoComponent } from './components/photo/photo.component';
import { DataService } from './components/data.service';

import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDividerModule } from '@angular/material/divider';
import { PreviewComponent } from './components/preview/preview.component';
import { PhotoPageComponent } from './components/photo-page/photo-page.component';
import { PreviewPageComponent } from './components/preview-page/preview-page.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';


@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    PhotoComponent,
    PreviewComponent,
    PhotoPageComponent,
    PreviewPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatTabsModule,
  ],
  providers: [DataService],
  bootstrap: [AppComponent],
})
export class AppModule { }
