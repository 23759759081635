import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-photo-page',
  templateUrl: './photo-page.component.html',
  styleUrls: ['./photo-page.component.scss']
})
export class PhotoPageComponent implements OnInit {

  multiply = window.outerHeight / 360;

  constructor() { }

  ngOnInit() {
    setTimeout(() => alert('Натисніть F11, а потім PrintScreen, щоб скопіювати зображення у буфер обміну.'),
      500);
  }

}
