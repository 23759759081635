import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { DataService, DataType } from '../data.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
import { BehaviorSubject } from 'rxjs';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'uk-UK' },
  ]
})
export class MainComponent implements OnInit {

  selectType = ['фото-заставка', 'відео-заставка'];
  data: DataType = new DataType();
  view: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private dateAdapter: DateAdapter<Date>, private service: DataService) { }

  ngOnInit() {
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
    this.service.getData().subscribe(data => this.data = data);
  }

  uploadPhoto(event) {
    if (event.target.files[0]) {
      const photo: File = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.onload = ((evt) => {
        this.data.image = (evt.target as FileReader).result.toString();
        this.service.setData(this.data);
      });
      fileReader.readAsDataURL(photo);
    }
  }

  changeMainText1(event) {
    this.data.mainText1 = event.target.value;
    this.service.setData(this.data);
  }

  changeMainText2(event) {
    this.data.mainText2 = event.target.value;
    this.service.setData(this.data);
  }

  changeMainText2Auto(event) {
    this.data.mainText2 = event.option.value;
    this.service.setData(this.data);
  }

  changeAdditionalText1(event) {
    this.data.additionalText1 = event.target.value;
    this.service.setData(this.data);
  }

  changeAdditionalText2(event) {
    this.data.additionalText2 = event.target.value;
    this.service.setData(this.data);
  }

  changeDate(event) {
    this.data.date = event.value._d;
    this.service.setData(this.data);
  }

  changeImage(image) {
    this.data.image = `/assets/images/${image}.jpg`;
    if (image === 'background') {
      this.data.mainText2 = 'Онлайн-служіння';
      this.data.additionalText2 = '';
    } else if (image === 'background2') {
      this.data.mainText2 = '';
      this.data.additionalText2 = 'Біблійні роздуми:';
    }
    this.service.setData(this.data);
  }

  changeView(view: boolean) {
    console.log(view);
    this.view.next(view);
  }

  changeSize(action: string) {
    if (action === '+') {
      this.data.mainTextSize += 0.01;
    } else if (action === '-') {
      this.data.mainTextSize -= 0.01;
    } else if (action === 'reset') {
      this.data.mainTextSize = 1;
    }
    this.service.setData(this.data);
  }

  changeLineSpace(action: string) {
    if (action === '+') {
      this.data.mainTextLineHeight += 0.01;
    } else if (action === '-') {
      this.data.mainTextLineHeight -= 0.01;
    } else if (action === 'reset') {
      this.data.mainTextLineHeight = 1;
    }
    this.service.setData(this.data);
  }

  changeLetterSpace(action: string) {
    if (action === '+') {
      this.data.mainTextLetterSpace += 0.1;
    } else if (action === '-') {
      this.data.mainTextLetterSpace -= 0.1;
    } else if (action === 'reset') {
      this.data.mainTextLetterSpace = 1;
    }
    this.service.setData(this.data);
  }
}
