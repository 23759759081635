import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class DataType {
  mainText1: string;
  mainText2: string;
  additionalText1: string;
  additionalText2: string;
  image: any;
  date: Date;
  mainTextSize: number;
  mainTextLineHeight: number;
  mainTextLetterSpace: number;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private data: DataType = {
    mainText1: 'Трансляція онлайн- богослужіння',
    mainText2: 'Онлайн-служіння',
    additionalText1: 'початок о 10:00',
    additionalText2: '',
    image: '/assets/images/background.jpg',
    date: new Date(),
    mainTextSize: 1,
    mainTextLineHeight: 1,
    mainTextLetterSpace: 1
  };
  private days = ['неділя', 'понеділок', 'вівторок', 'середа', 'четвер', 'п`ятниця', 'субота'];
  private monthes = ['січня', 'лютого', 'березня', 'квітня', 'травня', 'червня', 'липня', 'серпня', 'вересня', 'жовтня', 'листопада', 'грудня'];

  private data$: BehaviorSubject<DataType> = new BehaviorSubject<DataType>(this.data);
  private dataObs = this.data$.asObservable();

  constructor() { }

  getData() {
    return this.dataObs;
  }

  setData(data) {
    this.data$.next(data);
  }

  getDay() {
    return this.days[this.data.date.getDay()];
  }

  getMonth() {
    return this.monthes[this.data.date.getMonth()];
  }
}
