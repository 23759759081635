import { Component, OnInit, Input } from '@angular/core';
import { DataService, DataType } from '../data.service';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit {

  day$: Observable<string> = new Observable<string>();
  month$: Observable<string> = new Observable<string>();
  data$: Observable<DataType> = new Observable<DataType>();

  @Input() multiply: number;

  constructor(private service: DataService) { }

  ngOnInit() {
    this.data$ = this.service.getData();
    this.month$ = this.service.getData().pipe(filter(data => !!data.date), map(data => this.service.getMonth()));
    this.day$ = this.service.getData().pipe(filter(data => !!data.date), map(data => this.service.getDay()));
  }

}
