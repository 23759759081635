import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { DataType, DataService } from '../data.service';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  day$: Observable<string> = new Observable<string>();
  month$: Observable<string> = new Observable<string>();
  data$: Observable<DataType> = new Observable<DataType>();

  @Input() multiply: number;

  constructor(private service: DataService) { }

  ngOnInit() {
    this.data$ = this.service.getData();
    this.month$ = this.service.getData().pipe(filter(data => !!data.date), map(data => this.service.getMonth()));
    this.day$ = this.service.getData().pipe(filter(data => !!data.date), map(data => this.service.getDay()));
  }
}
